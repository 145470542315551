<template>
  <div class="tw-px-10 lg:tw-px-24 tw-bg-white tw-pt-36 tw-pb-20">
    <div class="row lg:tw-pb-20 column-gap-4">
      <div class="col-lg tw-flex tw-flex-row tw-justify-start lg:tw-justify-center">
        <img
          src="./asset/team_stock.jpeg"
          alt="team stock"
          class="tw-rounded-lg tw-max-w-72 md:tw-max-w-sm lg:tw-max-w-md tw-object-contain"
        />
      </div>
      <div class="col-lg tw-pt-8 lg:tw-pt-0">
        <h1 class="tw-text-secondary tw-text-4xl md:tw-text-6xl tw-font-light tw-pb-8">
          Meet Our Team
        </h1>
        <p class="tw-text-lg lg:tw-text-2xl">
          Scenera is a team of ambitious individuals who are passionate about building the world we
          want to live in, for the protection and safety of clients, employees, and community. We
          believe that technology has the power to change the way we live, and we’re working hard
          every day on making that happen.
        </p>
      </div>
    </div>
    <h1 class="tw-text-secondary tw-py-8">Our key team members</h1>
    <div class="row row-gap-4">
      <div v-for="(teamMember, index) in teamMembers" :key="index" class="col-md-6">
        <TeamMemberCard
          :key="index"
          :name="teamMember.name"
          :role="teamMember.role"
          :description="teamMember.description"
          :photo="teamMember.photo"
          :linkedin="teamMember.linkedin"
        />
      </div>
    </div>
    <h1 class="tw-text-secondary tw-py-8 tw-mt-8 md:tw-mt-16">Scenera’s Board of Directors</h1>
    <div class="row row-gap-4">
      <div v-for="(teamMember, index) in boardOfDirectors" :key="index" class="col-md-6">
        <TeamMemberCard
          :key="index"
          :name="teamMember.name"
          :role="teamMember.role"
          :description="teamMember.description"
          :photo="teamMember.photo"
          :linkedin="teamMember.linkedin"
        />
      </div>
    </div>
    <h1 class="tw-text-secondary tw-py-8 tw-mt-8 md:tw-mt-16">Scenera’s Strategic Advisor</h1>
    <div class="row row-gap-4">
      <div v-for="(teamMember, index) in strategicAdvisor" :key="index" class="col-md-6">
        <TeamMemberCard
          :key="index"
          :name="teamMember.name"
          :role="teamMember.role"
          :description="teamMember.description"
          :photo="teamMember.photo"
          :linkedin="teamMember.linkedin"
        />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import TeamMemberCard from './card/TeamMemberCard.vue';
const teamMembers = [
  {
    name: 'David Lee',
    role: 'Scenera CEO',
    description:
      'David Lee is a serial entrepreneur and currently Chief Executive Officer and Co-founder of Scenera Inc.In recalling the considerations for pursuing Scenera’s development, David Lee, Scenera CEO & Co-Founder says, “The reason we started to solve this is because there was so much focus on the IoT device itself, which nowadays, is highly powered and highly capable. However, we did not really think that building directly into the hardware was the most optimal decision.”',
    photo: 'david.jpg',
    linkedin: 'https://www.linkedin.com/in/david-d-lee-0bb6984/',
  },
  {
    name: 'Andrew Wajs',
    role: 'Scenera CTO',
    description:
      'Andrew Wajs is the Chief Technical Officer and co-founder of Scenera, Inc.He is responsible for the development of core IP and the long technology and product strategy for Scenera and serves also as a board member. He is working closely with partners like Sony, Nikon, Wistron, and Foxconn to develop the technology strategy, specifications, and first deployments.',
    photo: '/andrew.png',
    linkedin: 'https://www.linkedin.com/in/andrewwajs/',
  },
  {
    name: 'Patryk Laurent',
    role: 'Scenera CSO',
    description:
      'Dr. Patryk Laurent, Ph.D. currently holds the pivotal role of Chief Scientific Officer at Scenera. With a profound expertise in machine learning and data analytics, he plays a vital role in steering Scenera’s cutting-edge initiatives in these domains. His primary responsibility revolves around the enhancement of Scenera’s services, striking the perfect balance between edge and cloud AI solutions, all while safeguarding the ownership of models and data for Scenera’s valued customers. Furthermore, Dr. Laurent is instrumental in shaping and advancing the company’s strategic direction in Data Management.',
    photo: '/patryk.png',
    linkedin: 'https://www.linkedin.com/in/patryk-laurent/',
  },
  {
    name: 'John Park',
    role: 'Scenera CLO',
    description: 'John Park is the Chief Legal Officer as well as a board member at Scenera.',
    photo: '/john.png',
    linkedin: 'https://www.linkedin.com/in/john-park-a4b6935/',
  },
  {
    name: 'Eui Seok Hwang',
    role: 'Scenera Korea President',
    description:
      'President of Scenera Korea and a board member, responsible for operations and business developments in Korea',
    photo: '/eui.jpeg',
    linkedin: '',
  },
  {
    name: 'Tae Kun Woo',
    role: 'Scenera VP of Engineering',
    description:
      'Vice president of engineering and a board member, leading development teams to commercialize products and services.',
    photo: '/tae.jpeg',
    linkedin: '',
  },
];

const boardOfDirectors = [
  {
    name: 'Isao Yamamoto',
    role: 'Board of Directors',
    description: '',
    photo: '/isao.png',
    linkedin: 'https://www.linkedin.com/in/isao-yamamoto-6410b338',
  },
  {
    name: 'Yanagisawa Eita',
    role: 'Board of Directors',
    description: '',
    photo: '/yanagisawa.png',
    linkedin: 'https://www.linkedin.com/in/yanagisawa-eita-17326610a/',
  },
];

const strategicAdvisor = [
  {
    name: 'Dr. William J. Raduchel',
    role: 'Scenera Strategic Advisor',
    description:
      'Dr. William J. Raduchel is the Strategic Advisor at Scenera. “Our goal is to achieve Zero Image Video Surveillance, recognizing that the creation of these systems fully exploits the power of vision while protecting privacy by keeping the images within the camera and controlling which events are signaled by the sensor. NICE makes that possible by enabling camera sensors to effectively tell them what to do and how. People have legitimate privacy concerns when they see cameras, and we already have many laws mandating disclosure and forbidding use in some circumstances.”',
    photo: '/william.png',
    linkedin: 'https://www.linkedin.com/in/wjraduchel/',
  },
];
</script>
<style lang=""></style>

<!-- {
    name:'',
    role:'',
    description:'',
    photo:'',
    linkedin:''
} -->
