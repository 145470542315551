<template>
  <nav class="!tw-pt-32 !tw-bg-white tw-w-full">
    <div class="tw-flex tw-flex-row tw-justify-center tw-gap-4 md:tw-gap-8">
      <div
        v-for="(item, index) in navBarItems"
        :key="index"
        class="tw-font-semibold tw-cursor-pointer"
        @click="onMenuItemClick(item)"
      >
        <p
          class="tw-text-slate-400"
          aria-current="page"
          :class="{ '!tw-text-black': item == activeTab }"
        >
          {{ item }}
        </p>
      </div>
    </div>
  </nav>

  <div v-if="activeTab == 'Press Release'" class="tw-px-10 lg:tw-px-24 tw-bg-white">
    <h1 class="tw-m-0 tw-text-3xl md:tw-text-5xl tw-py-4 md:tw-py-8">{{ pressReleaseHeadline }}</h1>
    <div
      v-if="yearList.length"
      class="tw-flex tw-flex-row tw-gap-2 tw-overflow-x-scroll md:tw-overflow-auto tw-cursor-grab"
    >
      <YearFilterButton
        :year="'All'"
        :on-click="filterPressReleasesByYear"
        :is-active="activeYear == 'All'"
      />
      <YearFilterButton
        v-for="(year, index) in yearList"
        :key="index"
        :year="year"
        :is-active="activeYear == year"
        :on-click="filterPressReleasesByYear"
      />
    </div>

    <div class="tw-flex tw-flex-col gap-3 gap-md-5 tw-py-8">
      <PressReleaseRow
        v-for="(article, index) in filteredPressRelease"
        :key="index"
        :date="article.date"
        :title="article.title"
        :link="article.path"
      />
    </div>
  </div>

  <div v-if="activeTab == 'Media Highlights'" class="tw-px-10 lg:tw-px-24 tw-bg-white">
    <h1 class="tw-m-0 tw-text-3xl md:tw-text-5xl tw-py-4 md:tw-py-8">
      {{ mediaHighlightsHeadline }}
    </h1>
    <div class="row gap-3 md:tw-py-8">
      <div v-for="(article, index) in mediaHighlightsList" :key="index" class="col tw-pb-8">
        <NewsroomCard
          :image="article.image"
          :title="article.title"
          :tag="article.source"
          :date="article.date"
          :url="article.url"
        />
      </div>
    </div>
  </div>

  <div v-if="activeTab == 'Blog Articles'" class="tw-px-10 lg:tw-px-24 tw-bg-white">
    <h1 class="tw-m-0 tw-text-3xl md:tw-text-5xl tw-py-4 md:tw-py-8">{{ blogHeadline }}</h1>
    <div class="row position-relative FeaturedCard tw-py-8 tw-px-4 md:tw-px-0">
      <div class="col-md-7 tw-flex tw-flex-col tw-justify-center">
        <img
          :src="featuredBlog.image"
          alt="photo"
          class="tw-object-cover md:tw-w-full tw-max-h-80 tw-rounded-xl tw-min-h-52"
        />
      </div>
      <div class="col-md-5 !tw-p-8 tw-flex tw-flex-col tw-justify-center">
        <p class="tw-text-xl">
          <span class="tw-text-primary">{{ featuredBlog.tag }}</span> | {{ featuredBlog.date }}
        </p>
        <a
          :href="featuredBlog.path"
          class="tw-font-bold tw-text-xl md:tw-text-3xl tw-no-underline tw-text-black stretched-link"
          >{{ featuredBlog.title }}</a
        >
      </div>
    </div>
    <div class="row gap-3 md:tw-py-8">
      <div v-for="(article, index) in blogsList" :key="index" class="col tw-pb-8">
        <NewsroomCard
          :image="article.image"
          :title="article.title"
          :tag="article.tag"
          :date="article.date"
          :url="article.path"
        />
      </div>
    </div>
  </div>
</template>
<script lang="ts" setup>
import { onMounted, ref } from 'vue';
import NewsroomCard from './NewsroomCard.vue';
import PressReleaseRow from './rows/PressReleaseRow.vue';
import YearFilterButton from './button/YearFilterButton.vue';
import fetchImageUrl from '@/lib/fetchImageUrl';
import getPageInfo from '@/lib/getPageInfo';
import targetData from '@/lib/targetData';
const activeTab = ref('Press Release');
const activeYear = ref<number | 'All'>('All');
const navBarItems = ['Press Release', 'Media Highlights', 'Blog Articles'];
const yearList = ref<any[]>([]);
const pressReleaseHeadline = ref<string>('');
const mediaHighlightsHeadline = ref<string>('');
const blogHeadline = ref<string>('');
const pressReleaseList = ref<any[]>([]);
const mediaHighlightsList = ref<any[]>([]);
const blogsList = ref<any[]>([]);
const featuredBlog = ref<any>({});
// Hold the filtered Press Release
const filteredPressRelease = ref<any[]>([]);

function onMenuItemClick(item: string) {
  activeTab.value = item;
}

// Filter the Press Releases by year
function filterPressReleasesByYear(year: number | 'All') {
  if (typeof year === 'number') {
    // Filter by specific year
    const filtered = pressReleaseList.value.filter(
      (release) => Number(release.release_year) === year,
    );
    filteredPressRelease.value = filtered;
  } else {
    // If 'all', return all press releases
    filteredPressRelease.value = pressReleaseList.value;
  }
  activeYear.value = year;
}

async function processData() {
  const pageInfo = await getPageInfo('newsroom');
  const extractData: any = (key: string) => targetData(pageInfo, key);
  const press_release_headline_data: any = extractData(
    'press_release_section_headline',
  ).press_release_section_headline;
  const media_highlights_headline_data: any = extractData(
    'media_highlights_section_headline',
  ).media_highlights_section_headline;
  const blog_headline_data: any = extractData('blog_section_headline').blog_section_headline;
  const targetPressReleasesData: any = extractData('press_release_group');
  const targetMediaHighlightsData: any = extractData('media_highlight_group');
  const targetBlogsData: any = extractData('blogs_group');
  const processedPressReleaseData = await Promise.all(
    targetPressReleasesData?.press_release_group?.map(async (release: any) => {
      const title = release.parts.find((part: any) => part.name === 'press_release_title');
      const date = release.parts.find((part: any) => part.name === 'press_release_date');
      const path = release.parts.find((part: any) => part.name === 'press_release_path');
      const release_year = release.parts.find((part: any) => part.name === 'press_release_year');
      return {
        title: title?.content || '',
        date: date?.content || '',
        path: path?.content || '',
        release_year: release_year?.content || '',
      };
    }) || [],
  );
  const processedMediaHighlightsData = await Promise.all(
    targetMediaHighlightsData?.media_highlight_group?.map(async (highlight: any) => {
      const title = highlight.parts.find((part: any) => part.name === 'media_highlight_title');
      const date = highlight.parts.find((part: any) => part.name === 'media_highlight_date');
      const source = highlight.parts.find((part: any) => part.name === 'media_highlight_source');
      const url = highlight.parts.find((part: any) => part.name === 'media_highlight_url');
      const image = highlight.parts.find((part: any) => part.name === 'media_highlight_image');
      return {
        title: title?.content || '',
        date: date?.content || '',
        source: source?.content || '',
        url: url?.content || '',
        image_id: image?.image_id || '',
        image: await fetchImageUrl(image?.image_id || ''),
      };
    }) || [],
  );
  const processedBlogsData = await Promise.all(
    targetBlogsData?.blogs_group?.map(async (blog: any) => {
      const title = blog.parts.find((part: any) => part.name === 'blog_title');
      const date = blog.parts.find((part: any) => part.name === 'blog_date');
      const tag = blog.parts.find((part: any) => part.name === 'blog_tag');
      const path = blog.parts.find((part: any) => part.name === 'blog_path');
      const image = blog.parts.find((part: any) => part.name === 'blog_image');
      return {
        title: title?.content || '',
        date: date?.content || '',
        tag: tag?.content || '',
        path: path?.content || '',
        image_id: image?.image_id || '',
        image: await fetchImageUrl(image?.image_id || ''),
      };
    }) || [],
  );
  // Extract the unique values for year and convert to numbers
  const uniqueYears = new Set(
    processedPressReleaseData.map((release) => Number(release.release_year)),
  );

  // Turn the Set to Array (it will already contain numbers)
  const uniqueYearsArray = Array.from(uniqueYears);

  // Sort the array in descending order (most recent years first)
  uniqueYearsArray.sort((a, b) => b - a);

  pressReleaseHeadline.value = press_release_headline_data;
  mediaHighlightsHeadline.value = media_highlights_headline_data;
  blogHeadline.value = blog_headline_data;
  pressReleaseList.value = processedPressReleaseData;
  filteredPressRelease.value = processedPressReleaseData;
  mediaHighlightsList.value = processedMediaHighlightsData;
  featuredBlog.value = processedBlogsData.shift();
  blogsList.value = processedBlogsData;
  yearList.value = uniqueYearsArray;
}

onMounted(() => {
  processData();
});
</script>
<style></style>
