<template>
  <div class="tw-mt-20 tw-px-24 tw-py-12 tw-text-primary">
    <h1 class="tw-text-5xl tw-py-8">Terms Of Service</h1>
    <p>Scenera, Inc.</p>
    <p>Last Updated – January 26, 2023</p>
    <p>
      PLEASE READ THIS TERMS OF SERVICE (“TERMS OF SERVICE”) CAREFULLY. BY ACCESSING OR USING
      WWW.SCENERA.NET OR ANY OTHER WEBSITES OF SCENERA (COLLECTIVELY, THE “SITE”) IN ANY WAY,
      INCLUDING DOWNLOADING A SDK, API AND/OR BROWSING THE SITE, YOU REPRESENT THAT (1) YOU HAVE
      READ, UNDERSTAND, AND AGREE TO BE BOUND BY THESE TERMS OF SERVICE, (2) YOU ARE OF LEGAL AGE TO
      FORM A BINDING CONTRACT WITH SCENERA, AND (3) YOU HAVE THE AUTHORITY TO ENTER INTO THE TERMS
      OF SERVICE PERSONALLY OR ON BEHALF OF THE COMPANY YOU HAVE NAMED AS THE USER, AND TO BIND THAT
      COMPANY TO THE TERMS OF SERVICE. THE TERM “YOU” REFERS TO THE INDIVIDUAL OR LEGAL ENTITY, AS
      APPLICABLE, THAT VISITED THE SITE OR THAT DOWNLOADED THE SDK. IF YOU DO NOT AGREE TO BE BOUND
      BY THE TERMS OF SERVICE, YOU MAY NOT ACCESS OR USE THE SITE OR PLACE A PRE-ORDER.
    </p>
    <p>
      THE TERMS OF SERVICE LIMIT THE REMEDIES THAT MAY BE AVAILABLE TO YOU IN THE EVENT OF A
      DISPUTE.
    </p>
    <ol>
      <li>USE OF THE SITE.</li>
      <p>
        You are solely responsible for the use of the Site. By using the Site, you acknowledge that
        your use of the Site is solely at your own risk. Your use of the Site is subject to these
        Terms of Service and any additional terms provided by SCENERA (“Additional Terms”)
        (collectively, the “Terms”).
      </p>
      <li>ADDITIONAL TERMS.</li>
      <p>
        In addition to these Terms, the following Additional Terms apply to your use of the Site. By
        using the Site or downloading a SDK, API, you agree to be bound by these Additional Terms,
        as applicable, which are incorporated herein by reference:
      </p>
      <ul>
        <li v-for="(point, index) in additionalTerms" :key="index">{{ point }}</li>
      </ul>
      <p>
        In the event of a conflict between the terms and conditions in this document and the terms
        in any of the above Additional Terms, these terms and conditions in this document will
        control.
      </p>
      <li>THIRD PARTY CONTENT.</li>
      <p>
        a. General. SCENERA may contain or display through the Site various materials and content
        from third parties (“Third Party Materials”). The display on or through the Site of such
        Third Party Materials does not in any way imply, suggest, or constitute any sponsorship,
        endorsement, or approval by SCENERA of any third party or any affiliation between any such
        third party and SCENERA. Furthermore, in using and accessing the Site, you agree that
        SCENERA is in no way responsible for the accuracy, timeliness, or completeness of Third
        Party Materials. SCENERA’s display of specific Third Party Materials does not suggest a
        recommendation by SCENERA of the third party or any products, services, websites or plans
        offered. Your interaction with any third party (whether online or offline) is at your own
        risk, and SCENERA will have no liability with respect to the acts, omissions, errors,
        representations, warranties, breaches or negligence of any such third party or for any
        personal injuries, death, property damage, or other damages or expenses resulting from your
        interactions with the third party.
      </p>
      <p>
        b. Third Party Links and References. The Site may contain references or links to third-party
        materials and websites not controlled by SCENERA. SCENERA provides such information and
        links as a convenience to you and such links and references should not be considered
        endorsements or recommendations of such sites or any content, products or information
        offered on such sites. You acknowledge and agree that SCENERA is not responsible for any
        aspect of the information or content contained in any third party materials or on any third
        party sites accessible or linked from the Site.
      </p>
      <li>USE RESTRICTIONS.</li>
      <p>
        SCENERA does not allow use of the Site for any illegal, abusive purposes, or in any manner
        that interferes with or interrupts the proper functioning of the Site, including but not
        limited to, through the use of any bot, crawler, spider, or other automated process. If you
        violate any provision of these Terms, your permission to use the Site will terminate
        automatically. Additionally, SCENERA, in its sole discretion may suspend or terminate your
        access to the Site at any time, with or without notice. We also reserve the right to modify
        or discontinue the Site at any time (including, without limitation, by limiting or
        discontinuing certain features of the Site) without notice to you. We will have no liability
        whatsoever on account of any change to the Site or any suspension or termination of your
        access to or use of the Site
      </p>
      <li>SITE RESTRICTIONS.</li>
      <p>You agree that you will not do, or attempt to do, any of the following:</p>
      <p>
        Reproduce, duplicate, copy, sell, trade, resell, distribute or exploit any part of the Site,
        use the Site, access the Site, or use content obtained through the Site other than in
        accordance with all applicable Terms;
      </p>
      <p>Access or use the Site for any comparative or competitive research purposes;</p>
      <p>
        Remove, circumvent, disable, damage or otherwise interfere with any security-related
        features of the Site, or features that enforce limitations on the use of the Site, or any
        content therein;
      </p>
      <p>
        Use any manual or automated means to extract and/or compile content from the Site for any
        commercial purpose or otherwise;
      </p>
      <p>
        Interfere with or disrupt the Site, networks or servers connected to the Site, or violate
        the regulations, policies or procedures of such networks or servers;
      </p>
      <p>
        Undertake, cause, permit or authorize the modification, creation of derivative works,
        translation, reverse engineering, decompiling, disassembling, or hacking of all or part of
        the Site or any other part thereof, except and solely to the extent permitted by law, or
        otherwise attempt to use or access the Site other than as intended; or
      </p>
      <p>
        Use the Site in any manner whatsoever that could lead to a violation of any federal, state
        or local laws, rules or regulations.
      </p>
      <li>RESERVATION OF RIGHTS.</li>
      <p>
        The Site is owned and operated by SCENERA. The software, content, visual interfaces,
        interactive features, information, trademarks, logos, graphics, design, compilation,
        computer code, products, software, services, content, and all other elements of the Site
        (“SCENERA Materials”), are protected by copyright, trade dress, patent, and trademark laws
        of the United States and other jurisdictions, international conventions, and all other
        relevant intellectual property and proprietary rights, and applicable laws. All SCENERA
        Materials, including intellectual property rights therein and thereto, are the property of
        SCENERA or its subsidiaries or affiliated companies and/or third-party licensors. You may
        not sell, license, distribute, copy, modify, publicly perform or display, transmit, publish,
        edit, adapt, create derivative works from, or otherwise make unauthorized use of the SCENERA
        Materials. SCENERA reserves all rights not expressly granted in these Terms. You shall not
        acquire any right, title or interest to the SCENERA Materials, whether by implication,
        estoppel, or otherwise, except for the limited rights set forth in these Terms.
      </p>
      <li>TERM AND TERMINATION.</li>
      <p>
        These Terms will remain in effect until terminated. These Terms, and your rights and
        licenses hereunder, will terminate immediately upon your breach of the Terms. SCENERA may
        terminate these Terms, or limit or terminate your access to the Site at any time for any
        reason or no reason at all. Sections 2, 3 and 4-13 shall survive the termination of this
        Terms of Service for any reason, along with any provisions of the Additional Terms that
        expressly by their terms survive.
      </p>
      <li>MODIFICATIONS.</li>
      <p>
        SCENERA reserves the right, in its sole discretion, to add, remove, or modify features of
        the Site at any time for any reason without liability to you. SCENERA may change, modify,
        add, or remove portions of these Terms at any time by making such modified terms available
        to you on the Site. If SCENERA materially modifies these Terms, it will make reasonable
        efforts to notify you of the change. For example, we may send a message to your email
        address, if we have one on file, after such material changes are made. Any changes to the
        Terms will be effective immediately for new users and will be effective thirty (30) days
        after posting notice of such changes on the Site for existing users. SCENERA may require you
        to provide consent to the updated Terms in a specified manner before further use of the Site
        is permitted. If you do not agree to any change(s) after receiving a notice of such
        change(s), you shall stop using the Site. Otherwise, your continued use of the Site
        constitutes your acceptance of such change(s). PLEASE REGULARLY CHECK THE SITE TO VIEW THE
        THEN-CURRENT TERMS.
      </p>
      <li>INDEMNITY.</li>
      <p>
        You agree that you will be responsible for your use of the Site, and you agree to defend,
        indemnify, and hold harmless SCENERA and its officers, directors, employees, consultants,
        affiliates, subsidiaries and agents (collectively, the “SCENERA Entities”) from and against
        any and all claims, liabilities, damages, losses, and expenses, including reasonable
        attorneys’ fees and costs, arising out of or in any way connected with (i) your access to,
        use of, or alleged use of the Site at any time; (ii) your violation of these Terms or any
        representation, warranty, or agreements referenced herein, or any applicable law or
        regulation; (iii) your violation of any third-party right, including without limitation any
        intellectual property right, publicity, confidentiality, property or privacy right; or (iv)
        any disputes or issues between you and any third party. SCENERA reserves the right, at our
        own expense, to assume the exclusive defense and control of any matter otherwise subject to
        indemnification by you (and without limiting your indemnification obligations with respect
        to such matter), and in such case, you agree to cooperate with SCENERA’s defense of such
        claim.
      </p>
      <li>WARRANTY DISCLAIMER.</li>
      <p>
        THE SITE, AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE SITE ARE PROVIDED “AS IS”,
        WITHOUT WARRANTY OR CONDITION OF ANY KIND, EITHER EXPRESS OR IMPLIED. THE SCENERA ENTITIES
        SPECIFICALLY (BUT WITHOUT LIMITATION) DISCLAIM ALL WARRANTIES OF ANY KIND, WHETHER EXPRESS
        OR IMPLIED, RELATING TO THE SITE AND ALL MATERIALS AND CONTENT AVAILABLE THROUGH THE SITE,
        INCLUDING BUT NOT LIMITED TO (i) ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A
        PARTICULAR PURPOSE, TITLE, QUIET ENJOYMENT, OR NON-INFRINGEMENT; AND (ii) ANY WARRANTIES
        ARISING OUT OF COURSE OF DEALING, USAGE, OR TRADE. THE SCENERA ENTITIES DO NOT WARRANT THAT
        THE SITE OR ANY PART THEREOF, OR ANY MATERIALS OR CONTENT OFFERED THROUGH THE SITE, WILL BE
        UNINTERRUPTED, SECURE, OR FREE OF ERRORS, VIRUSES, OR OTHER HARMFUL COMPONENTS, AND DO NOT
        WARRANT THAT ANY OF THE FOREGOING WILL BE CORRECTED.
      </p>
      <p>
        NO INFORMATION, WHETHER ORAL OR WRITTEN, OBTAINED BY YOU FROM THE SITE OR ANY MATERIALS OR
        CONTENT AVAILABLE ON OR THROUGH THE SITE WILL CREATE ANY WARRANTY REGARDING THE SCENERA
        ENTITIES, OR THE SITE THAT IS NOT EXPRESSLY STATED IN THESE TERMS. YOU UNDERSTAND AND AGREE
        THAT YOUR USE OF THE SITE, AND ANY MATERIALS OR CONTENT THROUGH THE SITE AND ANY ASSOCIATED
        SITES OR SERVICES IS AT YOUR OWN DISCRETION AND RISK.
      </p>
      <p>
        SOME JURISDICTIONS MAY PROHIBIT A DISCLAIMER OF WARRANTIES AND YOU MAY HAVE OTHER RIGHTS
        THAT VARY FROM JURISDICTION TO JURISDICTION.
      </p>
      <li>11. LIMITATION OF LIABILITY.</li>
      <p>
        IN NO EVENT WILL THE SCENERA ENTITIES BE LIABLE TO YOU FOR ANY LOSS OF PROFITS, GOODWILL,
        USE, DATA, OR OTHER INTANGIBLE LOSSES OR INDIRECT, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR
        PUNITIVE DAMAGES ARISING OUT OF OR RELATING TO YOUR ACCESS TO OR USE OF, OR YOUR INABILITY
        TO ACCESS OR USE, THE SITEOR ANY MATERIALS OR CONTENT ON THE SITE, WHETHER BASED ON
        WARRANTY, CONTRACT, TORT (INCLUDING NEGLIGENCE), STATUTE OR ANY OTHER LEGAL THEORY, WHETHER
        OR NOT THE SCENERA ENTITIES HAVE BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE.
      </p>
      <p>
        YOU AGREE THAT THE AGGREGATE LIABILITY OF THE SCENERA ENTITIES TO YOU FOR ANY AND ALL CLAIMS
        ARISING OUT OF RELATING TO THE USE OF OR ANY INABILITY TO USE THE SITE (INCLUDING ANY
        MATERIALS OR CONTENT AVAILABLE THROUGH THE SITE) OR OTHERWISE UNDER THESE TERMS, WHETHER IN
        CONTRACT, TORT, OR OTHERWISE, IS LIMITED TO THE GREATER OF (i) THE AMOUNTS YOU HAVE PAID TO
        SCENERA IN THE 12 MONTHS PRIOR TO THE CLAIM OR (ii) $10.
      </p>
      <p>
        SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY FOR CONSEQUENTIAL
        OR INCIDENTAL DAMAGES. ACCORDINGLY, THE ABOVE LIMITATION MAY NOT APPLY TO YOU.
      </p>
      <p>
        EACH PROVISION OF THESE TERMS THAT PROVIDES FOR A LIMITATION OF LIABILITY, DISCLAIMER OF
        WARRANTIES, OR EXCLUSION OF DAMAGES IS TO ALLOCATE THE RISKS UNDER THESE TERMS BETWEEN THE
        PARTIES. THIS ALLOCATION IS AN ESSENTIAL ELEMENT OF THE BASIS OF THE BARGAIN BETWEEN THE
        PARTIES. YOU ACKNOWLEDGE AND AGREE THAT SCENERA HAS OFFERED ITS SITE, SET ITS PRICES, AND
        ENTERED INTO THIS AGREEMENT IN RELIANCE UPON THE WARRANTY DISCLAIMERS AND THE LIMITATIONS OF
        LIABILITY SET FORTH HEREIN, THAT THE WARRANTY DISCLAIMERS AND THE LIMITATIONS OF LIABILITY
        SET FORTH HEREIN REFLECT A REASONABLE AND FAIR ALLOCATION OF RISK BETWEEN YOU AND SCENERA,
        AND THAT THE WARRANTY DISCLAIMERS AND THE LIMITATIONS OF LIABILITY SET FORTH HEREIN FORM AN
        ESSENTIAL BASIS OF THE BARGAIN BETWEEN YOU AND SCENERA.
      </p>
      <p>
        EACH OF THESE PROVISIONS IS SEVERABLE AND INDEPENDENT OF ALL OTHER PROVISIONS OF THESE
        TERMS. THE LIMITATIONS IN THIS SECTION WILL APPLY EVEN IF ANY LIMITED REMEDY FAILS OF ITS
        ESSENTIAL PURPOSE.
      </p>
      <li>DISPUTE RESOLUTION.</li>
      <p>
        a. Applicability of Arbitration. All claims and disputes (excluding claims for injunctive or
        other equitable relief as set forth below) in connection with the Terms or the use of any
        product or service provided by SCENERA that cannot be resolved informally or in small claims
        court shall be resolved by binding arbitration on an individual basis under the terms of
        this Arbitration clause. Unless otherwise agreed, all arbitration proceedings will be held
        in English. This Arbitration clause applies to you and SCENERA, and to any subsidiaries,
        affiliates, agents, employees, predecessors in interest, successors, and assigns, as well as
        all authorized or unauthorized users or beneficiaries of services or goods provided under
        the Terms.
      </p>
      <p>
        b. Notice Requirement and Informal Dispute Resolution. Before either party may seek
        arbitration, the party must first send to the other party a written Notice of Dispute
        (“Notice”) describing the nature and basis of the claim or dispute, and the requested
        relief. A Notice to SCENERA should be sent to: Scenera, Inc., 890 Robb Road, Palo Alto, CA
        94306. After the Notice is received, you and SCENERA may attempt to resolve the claim or
        dispute informally. If you and SCENERA do not resolve the claim or dispute within thirty
        (30) days after the Notice is received, either party may begin an arbitration proceeding.
        The amount of any settlement offer made by any party may not be disclosed to the arbitrator
        until after the arbitrator has determined the amount of the award, if any, to which either
        party is entitled.
      </p>
      <p>
        c. Arbitration Rules. Arbitration shall be initiated through the American Arbitration
        Association (“AAA”), an established alternative dispute resolution provider (“ADR Provider”)
        that offers arbitration as set forth in this section. If AAA is not available to arbitrate,
        the parties shall agree to select an alternative ADR Provider. The rules of the ADR Provider
        shall govern all aspects of the arbitration, including but not limited to the method of
        initiating and/or demanding arbitration, except to the extent such rules are in conflict
        with the Terms. The AAA Consumer Arbitration Rules governing the arbitration are available
        online at www.adr.org or by calling the AAA at 1- 800-778-7879. The arbitration shall be
        conducted by a single, neutral arbitrator. Any claims or disputes where the total amount of
        the award sought is less than Ten Thousand U.S. Dollars (US $10,000.00) may be resolved
        through binding non-appearance-based arbitration, at the option of the party seeking relief.
        For claims or disputes where the total amount of the award sought is Ten Thousand U.S.
        Dollars (US $10,000.00) or more, the right to a hearing will be determined by the
        Arbitration Rules. Any hearing will be held in Santa Clara County, California, USA. If you
        reside outside of the U.S., the arbitrator shall give the parties reasonable notice of the
        date, time and place of any oral hearings. Any judgment on the award rendered by the
        arbitrator may be entered in any court of competent jurisdiction. Each party shall bear its
        own costs (including attorney’s fees) and disbursements arising out of the arbitration and
        shall pay an equal share of the fees and costs of the ADR
      </p>
      <p>
        d. Additional Rules for Non-Appearance Based Arbitration. If non-appearance based
        arbitration is elected, the arbitration shall be conducted by telephone, online and/or based
        solely on written submissions; the specific manner shall be chosen by the party initiating
        the arbitration. The arbitration shall not involve any personal appearance by the parties or
        witnesses unless otherwise agreed by the parties.
      </p>
      <p>
        Time Limits. If you or SCENERA pursue arbitration, the arbitration action must be initiated
        and/or demanded within the statute of limitations (i.e., the legal deadline for filing a
        claim) and within any deadline imposed under the AAA Rules for the pertinent claim.
      </p>
      <p>
        Authority of Arbitrator. If arbitration is initiated, the arbitrator will decide the rights
        and liabilities, if any, of you and SCENERA, and the dispute will not be consolidated with
        any other matters or joined with any other cases or parties. The arbitrator shall have the
        authority to grant motions dispositive of all or part of any claim. The arbitrator shall
        have the authority to award monetary damages, and to grant any non-monetary remedy or relief
        available to an individual under applicable law, the AAA Rules, and the Terms. The
        arbitrator shall issue a written award and statement of decision describing the essential
        findings and conclusions on which the award is based, including the calculation of any
        damages awarded. The arbitrator has the same authority to award relief on an individual
        basis that a judge in a court of law would have. The award of the arbitrator is final and
        binding upon you and SCENERA.
      </p>
      <p>
        Waiver of Jury Trial. THE PARTIES HEREBY WAIVE THEIR CONSTITUTIONAL AND STATUTORY RIGHTS TO
        GO TO COURT AND HAVE A TRIAL IN FRONT OF A JUDGE OR A JURY, instead electing that all claims
        and disputes shall be resolved by arbitration under this Arbitration clause. Arbitration
        procedures are typically more limited, more efficient and less costly than rules applicable
        in a court and are subject to very limited review by a court. In the event any litigation
        should arise between you and SCENERA in any state or federal court in a suit to vacate or
        enforce an arbitration award or otherwise, YOU AND SCENERA WAIVE ALL RIGHTS TO A JURY TRIAL,
        instead electing that the dispute be resolved by a judge.
      </p>
      <p>
        Waiver of Class or Consolidated Actions. ALL CLAIMS AND DISPUTES WITHIN THE SCOPE OF THIS
        ARBITRATION CLAUSE MUST BE ARBITRATED OR LITIGATED ON AN INDIVIDUAL BASIS AND NOT ON A CLASS
        BASIS, AND CLAIMS OF MORE THAN ONE PERSON OR USER CANNOT BE ARBITRATED OR LITIGATED JOINLY
        OR CONSOLIDATED WITH THOSE OF ANY OTHER PERSON OR USER.
      </p>
      <p>
        Confidentiality. All aspects of the arbitration proceeding, including but not limited to the
        award of the arbitrator and compliance therewith, shall be strictly confidential. The
        parties agree to maintain confidentiality unless otherwise required by law. This paragraph
        shall not prevent a party from submitting to a court of law any information necessary to
        enforce this clause, to enforce an arbitration award, or to seek injunctive or equitable
        relief which may be taken outside of this Arbitration clause.
      </p>
      Severability. If any part or parts of this Arbitration clause are found under the law to be
      invalid or unenforceable by a court of competent jurisdiction, then such specific part or
      parts shall be of no force and effect and shall be severed and the remainder of the Agreement
      shall continue in full force and effect.
      <p>
        Right to Waive. Any or all of the rights and limitations set forth in this Arbitration
        clause may be waived in writing by the party against whom the claim is asserted. Such waiver
        shall not waive or affect any other portion of the Terms of Service.
      </p>
      <p>
        Survival of Agreement. This Arbitration clause will survive the termination of your
        relationship with SCENERA.
      </p>
      <p>
        Small Claims Court. Notwithstanding the foregoing, either you or SCENERA may bring an
        individual action in small claims court.
      </p>
      <p>
        Emergency Equitable Relief. Notwithstanding the foregoing, either party may seek emergency
        equitable relief before a state or federal court in order to maintain the status quo pending
        arbitration. A request for interim measures shall not be deemed a waiver of any other rights
        or obligations under this Arbitration clause.
      </p>
      <p>
        Claims Not Subject to Arbitration. Notwithstanding the foregoing, claims of defamation,
        violation of the Computer Fraud and Abuse Act, and infringement or misappropriation of the
        other party’s patent, copyright, trademark or trade secrets shall not be subject to this
        Arbitration clause.
      </p>
      <p>
        Courts. In any circumstances where the foregoing Arbitration clause permits the parties to
        litigate in court, the parties hereby agree to submit to the personal jurisdiction of the
        courts located within Santa Clara County, California and federal courts located in the
        Northern District of California for such purpose.
      </p>
      <li>MISCELLANEOUS.</li>
      <p>
        a. Governing Law. These Terms, whether interpreted in a court of law or in arbitration,
        shall be governed by the laws of the State of California as they apply to agreements entered
        into and to be performed entirely within California by California residents, and without
        regard to conflict of law principles or the United Nations Convention for the International
        Sale of Goods.
      </p>
      <p>
        b. Entire Agreement. These Terms (including the various additional terms incorporated herein
        by reference) constitute the entire agreement between you and SCENERA, and supersedes any
        and all prior agreements, negotiations, or other communications between you and SCENERA,
        whether oral or written, with respect to the subject matter hereof, and, except as expressly
        provided herein, cannot be modified except in writing signed by both parties.
      </p>
      <p>
        c. Severability. In the event that any provision of these Terms is held to be invalid or
        unenforceable, then: (a) such provision shall be deemed reformed to the extent strictly
        necessary to render such provision valid and enforceable, or if not capable of such
        reformation shall be deemed severed from these Terms; and (b) the validity and
        enforceability of all of the other provisions hereof, shall in no way be affected or
        impaired thereby.
      </p>
      <p>
        d. Compliance with Laws. You agree to abide by all applicable laws in your use of the Site,
        including export control laws and similar regulations governing the transfer of software and
        other technology outside of the United States or other jurisdictions.
      </p>
      <p>
        e. Assignment. You may not assign these Terms without the prior written consent of SCENERA,
        whether expressly or by operation of law, including in connection with a merger or change of
        control, and any such attempted assignment shall be void and of no effect. SCENERA may
        assign these Terms without restriction. Subject to the foregoing, these Terms shall be
        binding on the parties and their respective successors and permitted assigns.
      </p>
      <p>
        f. Force Majeure. You acknowledge and understand that if SCENERA is unable to provide the
        Site as a result of a force majeure event SCENERA will not be in breach of these Terms. A
        force majeure event means any event beyond the control of SCENERA.
      </p>
      <p>
        g. Waiver. The failure to exercise, or delay in exercising, a right, power or remedy
        provided in these Terms or by law shall not constitute a waiver of that right, power or
        remedy. SCENERA’s waiver of any obligation or breach of these Terms shall not operate as a
        waiver of any other obligation or subsequent breach of these Terms.
      </p>
      <p>
        h. Consent to Electronic Communications. By using the Site, you consent to receiving certain
        electronic communications from us as further described in our Privacy Policy. Please read
        our Privacy Policy to learn more about your choices regarding our electronic communications
        practices. You agree that any notices, agreements, disclosures, or other communications that
        we send to you electronically will satisfy any legal communication requirements, including
        that such communications be in writing.
      </p>
      <p>
        i. Export. You may not use, export, import, or transfer information on the Sites, or any
        services accessible via the Site except as authorized by U.S. law, the laws of the
        jurisdiction in which you obtained access to the Site, and any other applicable laws. In
        particular, but without limitation, the services accessible via the Site may not be exported
        or re-exported (a) into any United States embargoed countries, or (b) to anyone on the U.S.
        Treasury Department’s list of Specially Designated Nationals or the U.S. Department of
        Commerce’s Denied Person’s List or Entity List. By using services accessible via the Site,
        you represent and warrant that (i) you are not located in a country that is subject to a
        U.S. Government embargo, or that has been designated by the U.S. Government as a “terrorist
        supporting” country and (ii) you are not listed on any U.S. Government list of prohibited or
        restricted parties.
      </p>
      <li>QUESTIONS, COMPLAINTS, AND CLAIMS.</li>
      <p>
        If you have any questions, complaints or claims, please contact us at
        help@contact.scenera.net. We will do our best to address your concerns. If you feel that
        your concerns have been addressed incompletely, we invite you to let us know for further
        investigation.
      </p>
    </ol>
  </div>
</template>
<script lang="ts" setup>
const additionalTerms = [
  'Privacy Policy, which describes SCENERA’s policies with respect to the collection, use, and disclosure of personal information from you.',
  'If you download a SDK, the SDK Agreement which governs your use of the SDK you may obtain from SCENERA.',
  'If you download an API, the API Agreement which governs your use of the API you may obtain from SCENERA.',
  'Any warranties or additional purchasing terms that SCENERA provides.',
  'Any additional terms or conditions SCENERA may supply from time to time.',
];
</script>
<style></style>
