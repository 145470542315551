<template>
  <p class="tw-font-semibold tw-text-black">Share The Article</p>
  <div class="tw-flex tw-mt-4 tw-gap-4">
    <div class="">
      <a
        :href="'https://www.facebook.com/sharer/sharer.php?u=' + 'https://scenera.net' + currentURL"
      >
        <img src="./asset/darkgray-facebook.png" alt="logo" class="tw-size-7" />
      </a>
    </div>
    <div class="">
      <a
        :href="'https://twitter.com/intent/tweet?text=&&url=' + 'https://scenera.net' + currentURL"
      >
        <img src="./asset/darkgray-twitterx.png" alt="logo" class="tw-size-7" />
      </a>
    </div>
    <div class="">
      <a
        :href="
          'https://www.linkedin.com/shareArticle?mini=true&url=' +
          'https://scenera.net' +
          currentURL
        "
      >
        <img src="./asset/darkgray-linkedin.png" alt="logo" class="tw-size-7" />
      </a>
    </div>
  </div>
  <p class="tw-text-black tw-font-semibold tw-py-8">Read more about Scenera</p>
  <div v-if="readMoreData.length" class="col gap-3">
    <div
      v-for="(article, index) in readMoreData"
      :key="index"
      class="col tw-pb-8 tw-flex tw-flex-row tw-justify-start"
    >
      <NewsroomCard
        :image="article.image"
        :title="article.title"
        :tag="article.tag"
        :date="article.date"
        :url="article.path"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, ref } from 'vue';
import NewsroomCard from './NewsroomCard.vue';
import fetchImageUrl from '@/lib/fetchImageUrl';
import getPageInfo from '@/lib/getPageInfo';
import targetData from '@/lib/targetData';
const readMoreData = ref<any[]>([]);
const currentURL = window.location.pathname;

async function processData() {
  const pageInfo = await getPageInfo('newsroom');
  const extractData: any = (key: string) => targetData(pageInfo, key);
  const targetBlogsData: any = extractData('blogs_group');
  const processedBlogsData = await Promise.all(
    targetBlogsData?.blogs_group?.map(async (blog: any) => {
      const title = blog.parts.find((part: any) => part.name === 'blog_title');
      const date = blog.parts.find((part: any) => part.name === 'blog_date');
      const tag = blog.parts.find((part: any) => part.name === 'blog_tag');
      const path = blog.parts.find((part: any) => part.name === 'blog_path');
      const image = blog.parts.find((part: any) => part.name === 'blog_image');
      return {
        title: title?.content || '',
        date: date?.content || '',
        tag: tag?.content || '',
        path: path?.content || '',
        image_id: image?.image_id || '',
        image: await fetchImageUrl(image?.image_id || ''),
      };
    }) || [],
  );
  readMoreData.value = processedBlogsData.slice(0, 3);
}

onMounted(() => {
  processData();
  console.log(readMoreData.value);
});
</script>
<style></style>
