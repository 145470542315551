<template>
  <div class="tw-mt-20 tw-px-24 tw-py-12 tw-text-primary">
    <h1 class="tw-text-5xl tw-py-8">Privacy Policy</h1>
    <p>Scenera, Inc.</p>
    <p><i>Effective date</i>: November 30, 2023</p>
    <p>
      Scenera, Inc. and our affiliates (“Scenera”) respect your concerns about privacy. This Privacy
      Notice describes the types of personal information we obtain about consumers and other
      individuals identified below, how we may use the personal information, with whom we may share
      it, and the choices available regarding our use of the personal information. The Privacy
      Notice also describes the measures we take to safeguard the personal information and how
      individuals can contact us about our privacy practices.
    </p>
    <h2>Information We Collect</h2>
    <h3>Information We Obtain About You</h3>
    <p>
      We obtain certain personal information in connection with the products and services we
      provide. The types of personal information we obtain include:
    </p>
    <ul>
      <li v-for="(point, index) in infoAboutYouPoints" :key="index">{{ point }}</li>
    </ul>
    <p>
      Privacy and video surveillance laws in your jurisdiction may apply to your use of our products
      and services. You are solely responsible for ensuring that you comply with applicable law when
      you use our products or services. For example, you may need to display a notice that alerts
      visitors to your home that you are using our products or services. Capturing, recording or
      sharing video or audio content that involves other people, or capturing other peoples’ facial
      feature information, may affect their privacy rights.
    </p>
    <h3>Information We Obtain by Automated Means</h3>
    <p>
      When you interact with our products and services, we obtain certain information by automated
      means, such as cookies, web server logs, web beacons, and other technologies. A “cookie” is a
      text file that websites send to a visitor’s computer or other Internet-connected device to
      uniquely identify the visitor’s browser or to store information or settings in the browser. A
      “web beacon,” also known as an Internet tag, pixel tag or clear GIF, links web pages to web
      servers and their cookies and may be used to transmit information collected through cookies
      back to a web server.
    </p>
    <p>
      We may use these automated technologies to collect information about your equipment, browsing
      actions, and usage patterns. The information we obtain in this manner may include your device
      IP address, identifiers associated with your devices, types of devices connected to our
      services, web browser characteristics, device characteristics, language preferences,
      referring/exit pages, clickstream data, and dates and times of website or app visits. These
      technologies help us (1) remember your information so you will not have to re-enter it; (2)
      track and understand how you use and interact with our products and services; (3) tailor the
      services around your preferences; (4) measure the usability of our products and services and
      the effectiveness of our communications; and (5) otherwise manage and enhance our products and
      services, and help ensure they are working properly.
    </p>
    <p>
      Your browser may tell you how to be notified when you receive certain types of cookies or how
      to restrict or disable certain types of cookies. Please note, however, that without cookies
      you may not be able to use all of the features of our online services. For mobile devices, you
      can manage how your device and browser share certain device data by adjusting the privacy and
      security settings on your mobile device.
    </p>
    <h2>How We Use The Information We Collect</h2>
    <p>We may use the personal information we obtain about you to:</p>
    <ul>
      <li v-for="(point, index) in howToUseInfoPoints" :key="index">{{ point }}</li>
    </ul>
    <p>
      We also may use the personal information we collect about you in other ways for which we
      provide specific notice at the time of collection and obtain your consent if required by
      applicable law.
    </p>
    <h3>Third-Party Web & App Analytics Services</h3>
    <p>
      We use third-party web and app analytics services on our websites and mobile apps. The service
      providers use automated technologies to collect and analyze information, including personal
      information (such as email and IP addresses) to understand how you use our websites and mobile
      apps. Web and app analytics services help us improve features, evaluate the effectiveness of
      our marketing, and, ultimately, optimize the customer experience. To learn more about these
      analytics services, please see Cookie Policy. To opt out of third-party web and app analytics
      services, please contact our
      <a href="mailto:support@contact.scenera.net">Customer Support</a>.
    </p>
    <h3>Information Sharing</h3>
    <p>
      We do not sell or otherwise share personal information about you except as described in this
      Privacy Notice. We may share your personal information with (1) our affiliates and
      subsidiaries and (2) our service providers who perform services on our behalf, such as
      marketing, customer service, order fulfillment and data analytics and storage. We do not
      authorize our service providers to use or disclose your personal information except as
      necessary to perform services on our behalf or comply with legal requirements. We also may
      share personal information with our business partners (1) with whom we jointly offer products
      and services; (2) to the extent you use Scenera’s API to connect to third-party products or
      services; (3) for payment processing and fraud prevention purposes and (4) with your consent.
      If you access the Scenera products and services through a third party or through an
      organization with admin access, we may share your personal information with that third party
      or the admin for your organization.
    </p>
    <p>
      We also may disclose personal information about you (1) if we are required to do so by law or
      legal process (such as a court order or subpoena); (2) to establish, exercise or defend our
      legal rights; (3) when we believe disclosure is necessary or appropriate to prevent physical
      or other harm or financial loss; (4) in connection with an investigation of suspected or
      actual illegal activity; or (5) otherwise with your consent.
    </p>
    <p>
      We reserve the right to transfer any personal information we have about you in the event we
      sell or transfer all or a portion of our business or assets (including in the event of a
      merger, acquisition, joint venture, reorganization, divestiture, dissolution or liquidation).
    </p>
    <h3>Your Choices</h3>
    <p>
      We offer you certain choices in connection with the personal information we obtain about you.
      To update your preferences, limit the communications you receive from us, or submit a request,
      please contact us as specified in the How To Contact Us section of this Privacy Notice We do
      need to send you certain communications about our products and services, and you will not be
      able to opt out of those communications. This includes, for example, information about
      billing.
    </p>
    <p>
      In addition, to the extent required by applicable law, you may have the right to request
      access to or delete your personal data. If you wish to do any of these things, please contact
      <a href="mailto:support@contact.scenera.net">Customer Support</a>
    </p>
    <h4>Additional State-Specific Privacy Disclosures</h4>
    <p>
      Please see US State Privacy Disclosures to read additional disclosures required under
      applicable state laws.
    </p>
    <h4>Other Third-Party Services and Features</h4>
    <p>
      Our products, services, or websites may allow you to link to those of third parties in order
      to provide certain features or experiences.
    </p>
    <p>
      In addition, our website and mobile apps may provide links to other online services for your
      convenience and information, and may include third-party features such as apps, tools, payment
      services, widgets and plug-ins (e.g., Facebook, Twitter or PayPal buttons).
    </p>
    <p>
      These third-party services and features may operate independently from us. The privacy
      practices of the relevant third parties, including details on the information they may collect
      about you, is subject to the privacy statements of these parties, which we strongly suggest
      you review. To the extent any linked online services or third-party features are not owned or
      controlled by us, Scenera is not responsible for these third parties’ information practices.
      By using or engaging with such services and features, you are requesting and authorizing us to
      transfer or provide access to information on your behalf, for example, your account ID, email
      address, data about your Scenera device usage or collected by your Scenera device, etc.
    </p>
    <h4>How We Protect Personal Information</h4>
    <p>
      We maintain administrative, technical and physical safeguards designed to protect personal
      information against accidental, unlawful or unauthorized destruction, loss, alteration,
      access, disclosure or use.
    </p>
    <h4>Policies for Children</h4>
    <p>
      We recognize the importance of protecting children’s online privacy. Scenera’s products and
      services are intended for a commercial audience and are not directed to or intended for
      children. We do not knowingly collect personal information online from children, including
      those under the age of 13.
    </p>
    <h4>Updates To Our Privacy Notice</h4>
    <p>
      This Privacy Notice may be updated periodically and without prior notice to you to reflect
      changes in our personal information practices. We will post a notice on our websites and
      mobile apps to notify you of significant changes to our Privacy Notice and indicate at the top
      of the notice when it was most recently updated.
    </p>
    <h4>Cookie Policy</h4>
    <p>
      Scenera, Inc. and our subsidiaries (collectively, “Scenera”, “we” or “our”) respect your
      concerns about privacy. We obtain certain information by automated means, such as cookies, web
      server logs, web beacons, and other technologies (collectively “cookies”) when you visit,
      access, or use our websites, services, mobile sites or applications of Scenera (collectively
      the “Sites”). This Cookie Policy explains how we use cookies, their purposes, and how you can
      control them. Our use of Cookies may involve the processing of personal data. For more
      information on our data protection practices, see our Privacy Notice.
    </p>
    <ol>
      <li>WHAT ARE COOKIES?</li>
      <p>
        A “cookie” is a text file that websites send to a visitor’s computer or other
        Internet-connected device to uniquely identify the visitor’s browser or to store information
        or settings in the browser. A “web beacon”, also known as an Internet tag, pixel tag or
        clear GIF, links web pages to web servers and may be used to transmit information collected
        through Cookies back to a web server.
      </p>
      <li>WHAT INFORMATION DO WE OBTAIN THROUGH COOKIES?</li>
      <p>
        The information we may obtain through cookies includes your device IP address, identifiers
        associated with your devices, types of devices connected to our services, web browser
        characteristics, device characteristics, language preferences, referring/exit pages,
        clickstream data, and dates and times of visits to Sites.
      </p>
      <li>COOKIES USED ON OUR SITES</li>
      <p>
        Below is a description of the cookies that we use on our Sites. You can manage cookies
        through your browser. Please note that, depending on your selection, you may not be able to
        take full advantage of the Sites or our products and services.
      </p>
      <ul>
        <li v-for="(point, index) in cookieDescriptions" :key="index">{{ point }}</li>
      </ul>
      <p>
        We currently do not use Advertising Cookies. In the future we may use Advertising Cookies to
        serve certain types of advertisements, including for products and services not available on
        Scenera.net and for certain ads relevant to your interests.
      </p>
      <p>
        Advertising Cookies may collect information on whether a user has reacted to an
        advertisement or visited another website or application. Such cookies help us offer you
        content and provide targeted information that seems more relevant to your interests. They
        also help us measure the effectiveness of our advertisements and control and limit the
        frequency of advertisements and other information being displayed to you.
      </p>
      <p>
        In some circumstances, we work with third parties to provide certain services to our users
        such as additional features including the ability to see videos, use the live chat functions
        on our Sites, and connect to certain social networks. These third parties use cookies to
        collect information on our Sites, including via plug-ins and widgets. We use third-party
        cookies to serve you relevant advertisements for products and services. We do not sell your
        personal information throughout this process.
      </p>
      <h6>Essential Cookies</h6>
      <p>
        Essential Cookies are necessary to navigate our Sites and use its functions and thus cannot
        be disabled. These cookies help us collect certain information, such as your session ID and
        other server information, which enables us to provide services such as authenticating users
        through security features like Two-Step Verification. Essential Cookies will remain on your
        browser until you remove them, which may impact your ability to navigate our Site and use
        its functions.
      </p>
      <li>YOUR CHOICES</li>
      <p>
        You can manage your data preferences using your internet browser setting. Most web browsers
        will tell you how to stop accepting new cookies, how to be notified when you receive a new
        cookie, how to disable or remove existing cookies and when cookies will expire. You can find
        out how to do this for your particular browser by clicking “help” on your browser’s menu or
        by visiting <a href="https://allaboutcookies.org/">https://allaboutcookies.org/</a>. For
        mobile devices, you can manage how your device and browser share certain device data by
        adjusting the privacy and security settings on your mobile device. Please note, however,
        that without certain cookies, you may not be able to take full advantage of the Sites or our
        products or services. For example, if you disable all essential cookies in your browser, you
        may not be able to add items to your shopping basket, proceed to checkout or use any our
        Sites that require login. You may also need to manually adjust some of your preferences
        every time you visit one of our Sites.
      </p>
    </ol>
    <h4>US State Privacy Disclosures</h4>
    <p>
      Scenera’s privacy policy describes the personal information that we collect, the sources from
      which we collect it, the purposes for which we use it, the limited circumstances under which
      we share personal information, and with whom we share it. These additional disclosures, which
      serve as a Notice at Collection under the California Privacy Rights Act, are required by the
      California Privacy Rights Act, Colorado Privacy Act, Connecticut Data Privacy Act, Utah
      Consumer Privacy Act, and Virginia Consumer Data Protection Act:
    </p>
    <ol>
      <li>
        <b>Categories of personal information collected.</b> The personal information that Scenera
        collects, or has collected, from consumers in the twelve months prior to the effective date
        of this Disclosure fall into the following categories established by the California Privacy
        Rights Act:
      </li>
      <ul>
        <li v-for="(point, index) in personalInformationCollected" :key="index">{{ point }}</li>
      </ul>
      <li>
        <b>Categories of personal information disclosed for a business purpose.</b> The personal
        information that Scenera disclosed to the third parties identified in the “Does Scenera
        Share Your Personal Information?” section of the Scenera Privacy Notice about consumers for
        a business purpose in the twelve months prior to the effective date of this Disclosure fall
        into the following categories established by the California Privacy Rights Act:
      </li>
      <ul>
        <li v-for="(point, index) in personalInformationDisclosed" :key="index">{{ point }}</li>
      </ul>
      <li>
        <b>Your Data Rights</b>: You may have certain rights under state privacy laws, including to
        request information about the collection of your personal information by Scenera, to access
        your personal information in a portable format, and to correct or delete your personal
        information. If you wish to do any of these things, please contact
        <a href="mailto:support@contact.scenera.net">Customer Support</a>. You may also have the
        right to appeal the denial of any of these rights by contacting contact
        <a href="mailto:support@contact.scenera.net">Customer Support</a>. Depending on your data
        choices, certain Scenera services may be limited or unavailable. To ensure the security of
        your Scenera account, we will generally ask you to verify your request using the contact
        information you have already provided. If you do not have an account, or if you are an
        authorized agent under applicable state law, contact
        <a href="mailto:support@contact.scenera.net">Customer Support</a>.
      </li>
      <li>
        <b>No Sale or Sharing of Personal Information.</b> In the twelve months prior to the
        effective date of this Disclosure, Scenera has not sold or shared any personal information
        of consumers, as those terms are defined under the California Privacy Rights Act.
      </li>
      <li>
        <b>California Privacy Rights Act Sensitive Personal Information Disclosure.</b> The
        categories of data that Scenera collects and discloses for a business purpose include
        “sensitive personal information” as defined under the California Privacy Rights Act. Scenera
        does not use or disclose sensitive personal information for any purpose not expressly
        permitted by the California Privacy Rights Act.
      </li>
      <li>
        <b>California Privacy Rights Act Retention Disclosure.</b> We keep your personal information
        to enable your continued use of Scenera, for as long as it is required in order to fulfill
        the relevant purposes described in the Scenera Privacy Notice, as permitted or as may be
        required by law, or as otherwise communicated to you.
      </li>
      <li>
        <b>California Privacy Rights Act Non-discrimination Statement.</b> Scenera will not
        discriminate against any consumer for exercising their rights under the California Privacy
        Rights Act.
      </li>
      <li>
        <b
          >California Privacy Rights Act, Colorado Privacy Act, Connecticut Data Privacy Act, Utah
          Consumer Privacy Act, and Virginia Consumer Data Protection Act De-identified Data
          Disclosure.</b
        >
        Scenera may use de-identified data in some instances. Scenera either maintains such data
        without attempting to re-identify it or treats such data as personal data subject to
        applicable law.
      </li>
      <li>
        <b>Colorado Privacy Act Profiling Disclosure.</b> Scenera does not engage in profiling of
        consumers in furtherance of automated decisions that produce legal or similarly significant
        effects, as those terms are defined under the Colorado Privacy Act.
      </li>
    </ol>

    <h4>How To Contact Us</h4>
    <p>
      If you have any questions about this Privacy Notice, or if you would like us to update
      information we have about you, change your preferences or exercise other applicable data
      protection rights, please contact us by e-mail at support@contact.scenera.net or write to us
      at:
    </p>
    <p class="tw-m-0">Scenera, Inc.</p>
    <p class="tw-m-0">890 Robb Road</p>
    <p class="tw-m-0">Palo Alto, CA 94306</p>
  </div>
</template>
<script lang="ts" setup>
const infoAboutYouPoints = [
  'Contact information, such as name, phone number, email, and postal address',
  'Account information, such as online password and other log-in details used to access Scenera products and services',
  'Payment information, such as name, billing address, and payment card details, including card number, expiration date, and security code (collected and stored by our third-party payment processor on our behalf; Scenera stores only the last four digits of your payment card and the expiration date)',
  "Product setup information, such as the name and description of your Scenera product (e.g., 'Scenera Bridge – Lobby'), the location of installation, and adjustments made to the product setup",
  'Technical information about your Scenera product, including Wi-Fi network information, signal strength, model, serial number, and software version',
  'Data about your interactions with our websites and mobile apps, including mobile network information',
  'Social media handles, content, and other data posted on our official social media pages',
  "Other personal information contained in content you submit to us, such as through our 'Contact Us' feature or customer support tools",
  'Content (and related information) captured and recorded when using our products and services, such as video or audio recordings, live streams, images, comments, and data collected from the environment (e.g., motion, events, temperature, ambient light)',
];

const howToUseInfoPoints = [
  'Provide our products and services to you;',
  'Establish and manage your Scenera account and profile;',
  'Process and fulfill claims and orders in connection with our products and services and keep you informed about the status of your order;',
  'Personalize your experience with our products and services;',
  'Allow you to stream, save and share content through our services;',
  'Identify and authenticate you so you may access certain content or use certain of our services;',
  'Increase and maintain the safety and security of our products and services and prevent misuse;',
  'Communicate with you (including providing you with offers and other communications about our products and services) and provide customer support;',
  'Perform analytics (including market and consumer research, trend analysis, financial analysis, and anonymization of personal information);',
  'Operate, evaluate, develop, manage and improve our business (including operating, administering, analyzing and improving our products and services; developing new products and services; managing and evaluating the effectiveness of our communications; performing accounting, auditing, billing reconciliation and collection activities and other internal functions);',
  'Protect against, identify and prevent fraud and other criminal activity, claims and other liabilities;',
  'Comply with and enforce applicable legal requirements, relevant industry standards and policies, including this Privacy Notice and our Terms of Service.',
];

const cookieDescriptions = [
  'Web and App Analytics Cookies',
  'We use Analytics Cookies to understand how users navigate and use our Sites. For example, these cookies collect information on the pages they view, how long they stay on a page and whether the page is displayed correctly or whether errors occur. Such cookies help us to improve the performance of our Sites in various ways, such as:',
  'Refining our Sites based on how customers use them;',
  'Optimizing the pages and features customers use the most;',
  'Testing different versions of pages and features to find the best solutions for customers;',
  'Advertising Cookies',
];

const personalInformationCollected = [
  'Identifiers such as your name, address, phone numbers, IP address, or your Scenera log-in information;',
  'Commercial information, such as purchase activity;',
  'Internet or other electronic network activity information, including website and application interaction information such as browsing and clickstream data;',
  'Audio or visual information, such as videoclips collected and stored in connection with the cloud services we provide;',
  'Professional information, such as data you may provide about your business if you interact with Scenera as a representative of a vendor or business partner of ours.',
];

const personalInformationDisclosed = [
  'Identifiers such as your name, address, phone numbers, or IP address, for example if you choose to connect with us through our customer support.',
  'Authentication credentials, such as your username and password, if we use a third-party service to verify your identity.',
  'Personal information, such as payment information.',
  'Commercial information, such as the details of a service you purchased if a third-party service provider is assisting us in providing that service to you.',
  'Audio or visual information, for example recordings of customer service phone calls for quality assurance purposes.',
];
</script>
<style></style>
