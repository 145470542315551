<template>
  <div
    v-if="!isSubmitted"
    class="tw-pt-32 md:tw-pt-52 tw-pb-24 tw-px-12 md:tw-px-24 row tw-bg-gradient-to-b tw-from-[#020024] tw-to-[#2b8fb4]"
  >
    <div class="col-md-5 tw-text-white">
      <h1 class="tw-bold tw-text-5xl md:tw-text-7xl tw-pb-4 md:tw-pb-8">Let's Get In Touch!</h1>
      <p class="tw-bold tw-text-lg md:tw-text-xl">
        Or reach out to us at <a href="mailto:info@scenera.net">info@scenera.net</a>
      </p>
    </div>
    <div class="col-md-1"></div>
    <form
      class="col-md-6 tw-rounded-xl tw-bg-lightBlue !tw-p-12 tw-font-semibold tw-text-secondary tw-flex tw-flex-col tw-gap-6 tw-shadow-lg"
      novalidate
      @submit.prevent="onSubmit"
    >
      <div>
        <p>I'm interested in...</p>
        <div class="tw-font-normal tw-flex tw-gap-4">
          <Option_button
            text="Book a demo"
            :is-active="state.interest === 'Book a demo'"
            :on-click="onInterestBtnClick"
          />
          <Option_button
            text="Press Contact"
            :is-active="state.interest === 'Press Contact'"
            :on-click="onInterestBtnClick"
          />
        </div>
      </div>
      <div class="row">
        <div class="col">
          <p>First Name*</p>
          <input
            v-model="state.firstName"
            type="text"
            class="tw-w-full tw-border-0 tw-rounded-lg tw-font-normal placeholder:tw-text-primary/40"
            placeholder="John"
            @blur="v$.firstName.$touch"
          />
          <div v-if="v$.firstName.$error" class="tw-text-sm tw-pt-2 tw-text-rose-400">
            First Name is required.
          </div>
        </div>
        <div class="col">
          <p>Last Name*</p>
          <input
            v-model="state.lastName"
            type="text"
            class="tw-w-full tw-border-0 tw-rounded-xl tw-font-normal placeholder:tw-text-primary/40"
            placeholder="Doe"
            @blur="v$.lastName.$touch"
          />
          <div v-if="v$.lastName.$error" class="tw-text-sm tw-pt-2 tw-text-rose-400">
            Last Name is required.
          </div>
        </div>
      </div>
      <div class="tw-w-full">
        <p>Job Title*</p>
        <input
          v-model="state.jobTitle"
          type="text"
          class="tw-w-full tw-border-0 tw-rounded-xl tw-font-normal placeholder:tw-text-primary/40"
          placeholder="Facility Manager"
          @blur="v$.jobTitle.$touch"
        />
        <div v-if="v$.jobTitle.$error" class="tw-text-sm tw-pt-2 tw-text-rose-400">
          Job title is required.
        </div>
      </div>
      <div>
        <p>Company Name*</p>
        <input
          v-model="state.companyName"
          type="text"
          class="tw-w-full tw-border-0 tw-rounded-xl tw-font-normal placeholder:tw-text-primary/40"
          placeholder="XYZ Company"
          @blur="v$.companyName.$touch"
        />
        <div v-if="v$.companyName.$error" class="tw-text-sm tw-pt-2 tw-text-rose-400">
          Company name is required.
        </div>
      </div>
      <div>
        <p>Email*</p>
        <input
          v-model="state.email"
          type="email"
          class="tw-w-full tw-border-0 tw-rounded-xl tw-font-normal placeholder:tw-text-primary/40"
          placeholder="johndoe@yourcompany.com"
          @blur="v$.email.$touch"
        />
        <div v-if="v$.email.$error" class="tw-text-sm tw-pt-2 tw-text-rose-400">
          Please enter a valid email address.
        </div>
      </div>
      <div>
        <p>Phone Number</p>
        <input
          v-model="state.phoneNumber"
          type="text"
          class="tw-w-full tw-border-0 tw-rounded-xl tw-font-normal placeholder:tw-text-primary/40"
          placeholder="(123)4567-8910"
        />
      </div>
      <div>
        <p>State/Region*</p>
        <input
          v-model="state.state"
          type="text"
          class="tw-w-full tw-border-0 tw-rounded-xl tw-font-normal placeholder:tw-text-primary/40"
          placeholder="California"
          @blur="v$.state.$touch"
        />
        <div v-if="v$.state.$error" class="tw-text-sm tw-pt-2 tw-text-rose-400">
          State/Region is required.
        </div>
      </div>
      <div>
        <p>Country/Region</p>
        <input
          v-model="state.country"
          type="text"
          class="tw-w-full tw-border-0 tw-rounded-xl tw-font-normal placeholder:tw-text-primary/40"
          placeholder="USA"
        />
      </div>
      <div class="tw-font-normal">
        <label class="tw-m-0 tw-mb-4 tw-font-semibold">
          <input
            v-model="state.consent"
            type="checkbox"
            class="tw-mr-2"
            @change="v$.consent.$touch()"
          />
          I agree to receive communications from Scenera.net*
          <div v-if="v$.consent.$error" class="tw-text-sm tw-pt-2 tw-text-rose-400">
            Consent is required to proceed.
          </div>
        </label>

        <p>
          By submitting this form you agree to receive communications from Scenera.net and agree to
          allow Scenera.net to store and process your personal data.
        </p>
      </div>
      <RecaptchaV2
        @widget-id="handleWidgetId"
        @error-callback="handleErrorCalback"
        @expired-callback="handleExpiredCallback"
        @load-callback="handleLoadCallback"
      />
      <button
        class="tw-py-2 tw-w-full tw-bg-secondary tw-rounded-3xl tw-text-white tw-font-bold tw-shadow-lg hover:tw-bg-secondary/90"
      >
        Submit Form
      </button>
    </form>
  </div>
  <div
    v-if="isSubmitted"
    class="tw-pt-52 tw-pb-24 tw-px-12 md:tw-px-24 row tw-bg-gradient-to-b tw-from-[#020024] tw-to-[#2b8fb4]"
  >
    <div
      class="tw-flex tw-flex-col tw-items-center tw-text-secondary tw-rounded-xl tw-bg-lightBlue tw-font-semibold tw-shadow-lg tw-gap-6 tw-py-16 tw-px-8 n tw-text-center"
    >
      <h1 class="tw-text-4xl md:tw-text-6xl">Thank you for reaching out!</h1>
      <h2 class="tw-text-2xl">Our team is reviewing your inquiry.</h2>
    </div>
  </div>
</template>
<script setup lang="ts">
import axios from 'axios';
import { ref, reactive } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required, email, sameAs } from '@vuelidate/validators';
import { RecaptchaV2 } from 'vue3-recaptcha-v2';
import Option_button from './button/Option_button.vue';

const handleWidgetId = (widgetId: number) => {
  console.log('Widget ID: ', widgetId);
};
const handleErrorCalback = () => {
  console.log('Error callback');
};
const handleExpiredCallback = () => {
  console.log('Expired callback');
};
const handleLoadCallback = (response: unknown) => {
  console.log('Load callback', response);
};

const state = reactive({
  interest: 'Book a demo',
  firstName: '',
  lastName: '',
  jobTitle: '',
  companyName: '',
  email: '',
  phoneNumber: '',
  state: '',
  country: '',
  consent: false,
});
const isSubmitted = ref<boolean>(false);

const rules = {
  firstName: { required, $lazy: true },
  lastName: { required, $lazy: true },
  jobTitle: { required, $lazy: true },
  companyName: { required, $lazy: true },
  email: { required, email, $lazy: true },
  state: { required, $lazy: true },
  consent: {
    sameAs: sameAs(true),
  },
};

const v$ = useVuelidate(rules, state);

function onInterestBtnClick(interest: string) {
  state.interest = interest;
  console.log(state.interest);
}

async function onSubmit() {
  const isFormCorrect = await v$.value.$validate();
  if (isFormCorrect) {
    console.log('submit');
    sendEmail();
    isSubmitted.value = true;
  } else {
    console.log('error');
  }
}

const formData = state;

function sendEmail() {
  axios
    .post('/api/v1/send_email', formData)
    .then((response) => {
      const message = response.data.message;
      console.log(message);
      // Clear form fields
      // this.recipientEmail = '';
      // this.emailSubject = '';
      // this.emailContent = '';
    })
    .catch((error) => {
      const errorMsg = error.response.data.error || 'An error occurred while sending the email';
      console.log(errorMsg);
    });
}
</script>
<style></style>
